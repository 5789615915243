import dayjs, { Dayjs } from 'dayjs';
import { merge } from 'lodash';

export enum DateRangesPredefined {
  last24hours = 'last24hours',
  last3days = 'last3days',
  last7days = 'last7days',
  last30days = 'last30days',
}

export class DateRange {
  constructor(initValue: Partial<DateRange>) {
    merge(this, initValue);
  }

  name!: string;
  start!: () => dayjs.Dayjs;
  end!: () => dayjs.Dayjs;
  applyStartTimezone = true;
  applyEndTimezone = true;

  static fromStartEnd(start: Dayjs, end: Dayjs) {
    return new DateRange({
      name: `${start.format('ddd DD MMM YYYY')} - ${end.format(
        'ddd DD MMM YYYY',
      )}`,
      start: () => start,
      end: () => end,
    });
  }
}

export const dateRanges: DateRange[] = [
  new DateRange({
    name: DateRangesPredefined.last24hours,
    start: () => dayjs().subtract(1, 'day'),
    end: () => dayjs().add(1, 'hour'),
  }),
  new DateRange({
    name: DateRangesPredefined.last3days,
    start: () => dayjs().subtract(3, 'days').startOf('day'),
    end: () => dayjs().add(1, 'hour'),
    applyStartTimezone: false,
  }),
  new DateRange({
    name: DateRangesPredefined.last7days,
    start: () => dayjs().subtract(7, 'days').startOf('day'),
    end: () => dayjs().add(1, 'hour'),
    applyStartTimezone: false,
  }),
  new DateRange({
    name: DateRangesPredefined.last30days,
    start: () => dayjs().subtract(30, 'days').startOf('day'),
    end: () => dayjs().add(1, 'hour'),
    applyStartTimezone: false,
  }),
];
